import { Routes, Route } from "react-router-dom"
import Home from "./pages/Home"
import 'remixicon/fonts/remixicon.css'

/** App */

function App (){
   return(
     <Routes>
        <Route index element={<Home />}/>
        <Route path="/" element={<Home/>}/>
        <Route path="*" element={1 > 0 ? <Home/> : <div>404</div>}/>
     </Routes>
  )
}

export default App;