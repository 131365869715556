import Header from "../components/Header";
import Logo from "../components/Logo";

function Home (){
   return(
    <div className="absolute inset-0 min-h-full pt-16 pb-12 flex flex-col from-slate-100 via-slate-100 bg-gradient-to-b to-slate-300">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center relative">
          <Logo className="block fill-current w-24 h-24 text-primary shadow-lg shadow-primary/50 animate-bounce" />
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-sm font-semibold text-primary uppercase tracking-wide">456 not ready</p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Work in progress.</h1>
            <p className="mt-2 text-base text-gray-500">Please be patient my friend.</p>
            <div className="mt-6">
              <a href="https://thecodecrowd.nl/" target="_blank" rel="noreferrer" className="text-base font-medium text-primary hover:text-primary">
                thecodecrowd.nl<i className="pl-2 ri-external-link-line"></i>
              </a>
            </div>
          </div>
        </div>
      </main>
      <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="flex justify-center space-x-4">
          <a href="/" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Contact Support
          </a>
          <span className="inline-block border-l border-gray-300" aria-hidden="true" />
          <a href="/" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Status
          </a>
          <span className="inline-block border-l border-gray-300" aria-hidden="true" />
          <a href="/" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Twitter
          </a>
        </nav>
      </footer>
    </div>
  )
}

export default Home;